import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import ScrollToTop from "react-scroll-up"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout>
      {/* <SEO title={post.title} description={post.excerpt} /> */}
      <Seo title={`${post.title} | Chad Rieder`} />
      <article
        className="blog-post pad-inner"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="post-header">
          <h1 itemProp="headline" className="hdg-1 post-hdg">{parse(post.title)}</h1>
          <span className="post-date">{post.date}</span>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              className="featured-image"
            />
          )}
        </header>

        {!!post.content && (
          <section itemProp="articleBody" className="post-main max-inner">
            {parse(post.content)}
          </section>
        )}
      </article>

      <ScrollToTop showUnder={500} duration={500} easing={'easeInOutSine'} role="button"><span className="back-to-top-btn">↑</span></ScrollToTop>

      <nav className="blog-post-nav">
        <div className="max-inner blog-nav-inner">
          <ul
            className="prev-next-list"
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
              margin: 0
            }}
          >
            <li className="prev hover-blue">
              {previous && (
                <Link to={previous.uri} rel="prev" className="prev">
                  ← {parse(previous.title)}
                </Link>
              )}
            </li>

            <li className="next hover-blue">
              {next && (
                <Link to={next.uri} rel="next" className="next">
                  {parse(next.title)} →
                </Link>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
